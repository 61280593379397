<script
    src="../../../../../../../AppData/Local/Temp/Rar$DRa14036.14120/src/app/helper/alertTimer/alert-timer.service.ts"
></script>
<template>
  <!-- The Modal -->
  <div>
    <b-card-code
      title=""
    >
      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="12">
              <Translations @lang="handleLanguage($event)" />
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-form-group>
                <label for="title">Title</label>
                <validation-provider
                  #default="{ errors }"
                  name="Title"
                  rules="required"
                >
                  <b-form-input
                    id="title"
                    v-model="editedWalkThrough.title"
                    name="title"
                    :state="errors.length > 0 ? false:null"
                    placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-form-group>
                <label for="image">Cover</label>
                <validation-provider
                  #default="{ errors }"
                  name="image"
                  rules=""
                >
                  <vue-dropzone
                    id="image"
                    ref="walkThroughImageRef"
                    name="image"
                    :options="dropzoneOptions"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-form-group>
                <label for="description">Description</label>
                <validation-provider
                  #default="{ errors }"
                  name="description"
                  rules="required"
                >
                  <template>
                    <quill-editor
                      id="content"
                      v-model="editedWalkThrough.description"
                      name="description"
                      :options="snowOption"
                    />
                  </template>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Modal footer -->
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              @click.prevent="validationForm"
            >
              Submit
            </button>
          </div>
        </b-form>
      </validation-observer>

      <template #code>
        {{ codeMultiple }}
      </template>
    </b-card-code>
  </div>

</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BFormTextarea, BFormDatepicker
} from 'bootstrap-vue'
import {
  required, email, confirmed, password,
} from '@validations'
import vSelect from 'vue-select'
import vue2Dropzone from 'vue2-dropzone'
import {codeMultiple, codeBasic} from './code'
import FormTextAreaDefault from '../../../shared/form-textarea/FormTextAreaDefault.vue'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { quillEditor } from 'vue-quill-editor'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
import walkThroughService from '@/services/walkThroughService'
import Translations from '../../../shared/translations/Translations.vue'

// eslint-disable-next-line
export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,

    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BFormTextarea,
    FormTextAreaDefault,
    BFormDatepicker,
    vSelect,
    vueDropzone: vue2Dropzone,
    quillEditor,
    Translations
  },
  data() {
    return {
      required,
      codeMultiple,
      item: '',
      editedWalkThrough: {
        title: '',
        description: '',
        image: ''
      },
      codeBasic,
      snowOption: {
        theme: 'snow',
      },
      newFile: false,
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        addRemoveLinks: false,
        acceptedFiles: '.jpeg,.jpg,.png,.gif',
        dictRemoveFile: 'Remove',
        maxFiles: 1,
        init: function() {
          this.on("maxfilesexceeded", function(file) {
            this.newFile = true;
            this.removeAllFiles();
            this.addFile(file);
          });
        },
      },
    }
  },
  mounted() {
    this.getBanner(this.$route.params.id)
  },
  methods: {
    handleLanguage(lang) {
      walkThroughService.show(this.$route.params.id, { lang })
          .then(response => {

            this.editedWalkThrough= {
              id: response.data.data.id,
              title: response.data.data.title,
              description: response.data.data.description,
            }
          })
    },
    getBanner(id) {
      this.loading = true
      walkThroughService.show(id).then(response => {
        this.item = response.data.data
        this.editedWalkThrough= {
          title: this.item.title,
          description: this.item.description,
          image: null,
        }
        this.$refs.walkThroughImageRef.manuallyAddFile(this.item.image[0].file, this.item.image[0].url)

        this.loading = false
      })
    },
    validationForm() {
      this.$refs.simpleRules.validate()
          .then(success => {
            if (success) {
              this.editedWalkThrough.image = this.$refs.walkThroughImageRef.getAcceptedFiles()[0]
              walkThroughService.put(this.$route.params.id, this.editedWalkThrough).then(response => {
                if (response && response.data.code === 200) {
                  this.$router.push({ name: 'walkThrough' })
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Success',
                      icon: 'CheckIcon',
                      variant: 'success',
                      text: 'Banner Is Updated Successfully !',
                    },
                  })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Error',
                      icon: 'CheckIcon',
                      variant: 'danger',
                      text: response.data.message,
                    },
                  })
                }
                this.loading = false
              })

            }
          }).catch(function (error) {

        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Success',
            icon: 'CheckIcon',
            variant: 'Error',
            text: 'Error !',
          },
        })
        return Promise.reject(error)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

label {
  font-weight: bold;
}

#createModal svg {
  filter: invert(69%) sepia(14%) saturate(4763%) hue-rotate(3deg) brightness(100%) contrast(43%) !important;
}
</style>
